import Link from 'next/link'

export function Footer() {
  return (
    <footer className='bg-white dark:bg-black dark:border-t dark:border-gray-200'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'>
        <div className='flex justify-center space-x-6 md:order-2'>
          <Link href='/legal/terms' className='text-gray-400 hover:text-gray-500'>
            Terms of Service
          </Link>
          <Link href='/legal/privacy' className='text-gray-400 hover:text-gray-500'>
            Privacy Policy
          </Link>
        </div>
        <div className='mt-8 md:mt-0 md:order-1'>
          <p className='text-center text-base text-gray-400'>
            &copy; 2021 Lunara Capital, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
