import type { User, UserCredentials } from 'api/types'
import { useEffect, useState } from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type UseAuthState = {
  user: User | null
  credentials: UserCredentials | null
  setUser: (u: User | null) => void
  setCredentials: (c: UserCredentials) => void
  clear: () => void
  init: (payload: { user: User; credentials: UserCredentials }) => void
  _hasHydrated: boolean
  setHasHydrated: (state: boolean) => void
}

/**
 * see https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data#persist-middleware for docs on
 * zustand persist middleware
 * */

export const useAuthStore = create<UseAuthState>()(
  persist(
    (set) => ({
      user: null,
      credentials: null,
      _hasHydrated: false,
      setUser: (user: User | null) => set({ user }),
      setCredentials: (credentials: UserCredentials) => set({ credentials }),
      init: ({ user, credentials }: { user: User; credentials: UserCredentials }) =>
        set({ user, credentials }),
      clear: () => set({ user: null, credentials: null }),
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state
        })
      }
    }),
    {
      name: 'lunara.auth', // This is the only required option. The given name is going to be the key used to store your Zustand state in the storage, so it must be unique.
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      version: 1, // If you want to introduce a breaking change in your storage (e.g. renaming a field), you can specify a new version number. see https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data#version
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true)
      }
    }
  )
)

export function useAuthCredentials() {
  return useAuthStore((state) => state.credentials)
}

export function useAuthUser() {
  return useAuthStore((state) => state.user)
}

export function useAuthStoreHasHydrated() {
  return useAuthStore((state) => state._hasHydrated)
}

export function useAuthUserHydrationWorkaround() {
  const _user = useAuthUser()
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    if (_user && !user) {
      setUser(_user)
    }
  }, [user, _user])

  return user
}
