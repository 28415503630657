import { CheckIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Multi-region',
    description: 'We trade every market in the AWS region closest to the respective exchange.'
  },
  {
    name: 'Notifications',
    description: 'Setup real-time Slack notifications to keep you updated on every trade.'
  },
  {
    name: 'Unlimited Bots',
    description: 'Create unlimitted bots across any number of exchanges and markets.'
  },
  {
    name: 'Hosted Dashboard',
    description:
      'Beautiful real-time dashboard to monitor all bots and account. Even on your phone.'
  }
]

export function Features() {
  return (
    <div id='features' className='bg-white dark:bg-black dark:border-t dark:border-gray-200'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8'>
        <div className='max-w-3xl mx-auto text-center'>
          <h2 className='text-3xl font-extrabold dark:font-semibold text-gray-900 dark:text-gray-200'>
            Lunara Trading Platform
          </h2>
          <p className='mt-4 text-lg text-gray-500 dark:text-gray-400'>
            Built to handle hundreds of bots across thousands of markets.
          </p>
        </div>
        <dl className='mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8'>
          {features.map((feature) => (
            <div key={feature.name} className='relative'>
              <dt>
                <CheckIcon className='absolute h-6 w-6 text-green-500' aria-hidden='true' />
                <p className='ml-9 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100'>
                  {feature.name}
                </p>
              </dt>
              <dd className='mt-2 ml-9 text-base text-gray-500 dark:text-gray-400'>
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
