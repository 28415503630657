'use client'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useAuthUser } from 'lib/auth'
import Image from 'next/image'
import Link from 'next/link'
import { CompanyStats } from 'ui/components/company-stats'
import { ContactUs } from 'ui/components/contact-us'
import { Features } from 'ui/components/features'
import { Footer } from 'ui/components/footer'
import { Team } from 'ui/components/team'

export default function LandingPage() {
  const user = useAuthUser()

  const userAction = user ? (
    <Link href='/console/dashboard'>Go to Dashboard</Link>
  ) : (
    <Link href='/login'>Sign In</Link>
  )

  return (
    <div>
      <div className='flex flex-col items-center justify-center h-screen bg-teal dark:bg-gray-800'>
        <Image
          className='rounded-lg'
          src='/logo/logo.png'
          alt='Lunara Capital'
          width={300}
          height={300}
        />
        <div>
          <div className='flex flex-row items-center mt-6 space-x-12 font-medium dark:text-gray-200 '>
            <a href='#features' className='hidden sm:inline-block'>
              Platform
            </a>
            <a href='#company-stats' className='hidden sm:inline-block'>
              Stats
            </a>
            <a href='#team' className='hidden sm:inline-block'>
              Team
            </a>
            <a href='#contact-us' className='hidden sm:inline-block'>
              Contact Us
            </a>
            <div className='flex flex-row items-center'>
              {userAction}
              <ArrowRightIcon className='block w-6 h-6 ml-1 text-black dark:text-gray-200' />
            </div>
          </div>
        </div>
      </div>
      <Features />
      <CompanyStats />
      <Team />
      <ContactUs />
      <Footer />
    </div>
  )
}
